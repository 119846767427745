import { getToken } from "../auth";
import { config } from "../../constants/config";
import { Response } from "../../types";
import { Content } from "../content";
import { Topic } from "./types";

export const getAllTopics = async () => {
  const accessToken = getToken();

  const response = await fetch(`${config.API_URL}/topic/all`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      "ngrok-skip-browser-warning": "skip-browser-warning",
    },
  });

  if (response.status !== 200) {
    const respJson: Response<void> = await response.json();
    throw new Error(respJson.message);
  }

  const { data }: Response<Topic[]> = await response.json();

  return data;
};
