import React, { useCallback, useEffect, useState } from "react";
import { Box, styled, TextField, Stack } from "@mui/material";
import { Content, uploadImage } from "../../services/content";
import { DropZone, ImageFile } from "../DropZone";

const StyledContentContainer = styled(Stack)({
  width: "100%",
  overflowY: "auto",
  maxHeight: "700px",
  paddingTop: "12px",
});

const StyledDropZoneContainer = styled(Box)({
  backgroundColor: "white",
  display: "flex",
  justifyContent: "center",
});

const StyledTextField = styled(TextField)({
  borderRadius: 4,
  border: "1px solid #ccc",
  backgroundColor: "white",
  "&:focus": {
    border: "1px solid #000",
  },
});

export interface ContentProps {
  content: Partial<Content> | null;
  contentType: string;
  onContentChange: (content: Partial<Content>) => void;
  onValidate: (isValid: boolean) => void;
  disabled?: boolean;
}

const fieldPerType: Record<string, string[]> = {
  verse: ["source", "mainText"],
  prayer: ["mainText"],
  reflection: ["title", "author", "mainText"],
};

const fieldToLabel: Record<string, string> = {
  source: "Source",
  mainText: "Main Text",
  author: "Author",
  title: "Title",
};

export const ContentForm: React.FC<ContentProps> = ({
  content,
  contentType,
  onContentChange,
  disabled,
  onValidate,
}) => {
  const [isUploading, setIsUploading] = useState(false);

  const onFileDrop = useCallback(
    async (files: ImageFile[]) => {
      const [file] = files;

      setIsUploading(true);

      const uploadFileResponse = await uploadImage(file.file);

      onContentChange({
        imageUrl: uploadFileResponse.Location,
      });

      setIsUploading(false);
    },
    [onContentChange],
  );

  const getFields = useCallback(() => {
    return fieldPerType[contentType] ?? [];
  }, [contentType]);

  // const onContentChangeHandler = useCallback(
  //   (field: string, value: string) => {
  //     onContentChange({ [field]: value });
  //   },
  //   [onContentChange]
  // );
  //
  // useEffect(() => {
  //   const isValid = getFields()
  //     .concat("imageUrl")
  //     .every((field) => content?.[field as keyof Content]);
  //
  //   onValidate(isValid);
  // }, [content, getFields, onValidate]);

  const onContentChangeHandler = useCallback(
    (field: string, value: string) => {
      onContentChange({
        ...content,
        [field]: value,
      });
    },
    [content, onContentChange],
  );

  useEffect(() => {
    const isValid = getFields()
      .filter((field) => field !== "author")
      .every((field) => content?.[field as keyof Content]);
    onValidate(isValid);
  }, [content, onValidate]);

  return (
    <StyledContentContainer gap={2}>
      {/*<StyledDropZoneContainer>*/}
      {/*<DropZone*/}
      {/*    currentFile={content?.imageUrl}*/}
      {/*    uploading={isUploading}*/}
      {/*    onDrop={onFileDrop}*/}
      {/*    disabled={disabled}*/}
      {/*/>*/}
      {/*</StyledDropZoneContainer>*/}
      {contentType.toUpperCase()}
      {getFields().map((field: any) => {
        const value = content ? content[field as keyof Content] : "";
        return (
          <StyledTextField
            key={field}
            disabled={disabled}
            id="outlined-multiline-flexible"
            label={fieldToLabel[field]}
            multiline
            value={value}
            maxRows={1000}
            onChange={(e) => onContentChangeHandler(field, e.target.value)}
          />
        );
      })}
    </StyledContentContainer>
  );
};
