import React, { useCallback, useEffect, useState } from "react";
import { Box, FormControl, InputLabel, MenuItem, styled } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useContentModal } from "../../context/content-modal-context";
import { ContentForm } from "./Content";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Content, CONTENT_TYPE, uploadImage } from "../../services/content";
import { useContentList } from "../../context/content-list-context";
import { DropZone, ImageFile } from "../DropZone";
import { Devotional } from "../../services/devotional";
import Select from "@mui/material/Select";
import { getAllTopics, Topic } from "../../services/topic";

const StyledModalInnerContainer = styled(Box)({
  flexDirection: "column",
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxWidth: 600,
  backgroundColor: "white",
  maxHeight: 800,
  display: "flex",
  paddingLeft: 16,
  paddingRight: 16,
  paddingBottom: 48,
  borderRadius: 4,
  justifyContent: "space-around",
  height: "100%",
  overflowY: "auto",
});

// const StyledContentContainer = styled(Box)({
//   flexGrow: 1,
//   padding: 16,
//   paddingTop: 60,
//   paddingBottom: 16,
//   display: "flex",
//   flexDirection: "row",
// });

const StyledContentContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  padding: 16,
});

const titleByType: Record<string, string> = {
  verse: "Verse",
  prayer: "Prayer",
  reflection: "Reflection",
};

const StyledDropZoneContainer = styled(Box)({
  marginTop: 12,
});

export const ContentModal: React.FC = () => {
  const {
    handleClose,
    open,
    updateContent,
    content,
    contentType,
    dateString,
    isPast,
    handleSave,
    saving,
    updateDevotional,
    devotional,
    handleTopicSelection,
  } = useContentModal();

  const { fetchContent } = useContentList();

  const [isFormValid, setIsFormValid] = useState(false);
  const [formValidations, setFormValidations] = useState<
    Partial<Record<CONTENT_TYPE, boolean>>
  >({});

  // const handleFormValidate = useCallback((isFormValid: boolean) => {
  //   setIsFormValid(isFormValid);
  // }, []);

  const onSave = useCallback(async () => {
    await handleSave();

    await fetchContent();

    handleClose();
  }, [handleSave, fetchContent, handleClose]);

  const onContentChange = useCallback(
    (updatedField: Partial<Content>) => {
      updateContent(updatedField);
    },
    [updateContent],
  );

  const onDevotionalChange = useCallback(
    (updatedField: Partial<Devotional>) => {
      updateDevotional(updatedField);
    },
    [updateDevotional],
  );

  let modalContent = null;

  // if (contentType) {
  //   modalContent = (
  //     <>
  //       <ContentForm
  //         disabled={isPast}
  //         contentType={contentType}
  //         onContentChange={onContentChange}
  //         content={content}
  //         onValidate={handleFormValidate}
  //       />
  //     </>
  //   );
  // }

  let title = dateString;

  if (contentType) {
    title = `${dateString} - ${titleByType[contentType]} `;
  }

  const contentTypes = ["verse", "prayer", "reflection"];
  const [isUploading, setIsUploading] = useState(false);

  const onFileDrop = useCallback(
    async (files: ImageFile[]) => {
      const [file] = files;

      setIsUploading(true);

      const uploadFileResponse = await uploadImage(file.file);

      onDevotionalChange({
        imageUrl: uploadFileResponse.Location,
      });

      setIsUploading(false);
    },
    [onContentChange],
  );

  const [selectedTopic, setSelectedTopic] = useState<Topic | null>(null);
  const [topics, setTopics] = useState<Topic[]>([]);

  const handleSelectTopic = (name: string) => {
    const topic = topics.find((item) => item.name === name);
    if (!topic) return;
    setSelectedTopic(topic);

    if (topic) {
      handleTopicSelection(topic);
    } else {
      console.warn("Topic not found:", name);
    }
  };

  const fetchTopics = async () => {
    const res = await getAllTopics();

    setTopics(res);
  };

  useEffect(() => {
    if (open) {
      fetchTopics();
    }
  }, [open]);

  const handleFormValidate = useCallback(
    (contentType: CONTENT_TYPE, isFormValid: boolean) => {
      setFormValidations((prev) => {
        if (prev[contentType] === isFormValid) return prev;

        const updatedValidations = { ...prev, [contentType]: isFormValid };

        const allValid =
          contentTypes.every(
            (type) => updatedValidations[type as CONTENT_TYPE] ?? false,
          ) && Boolean(devotional?.imageUrl);

        setIsFormValid(allValid);
        return updatedValidations;
      });
    },
    [contentTypes, devotional],
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledModalInnerContainer>
        <Header title={title} onClose={handleClose} />
        <FormControl style={{ marginTop: 10 }} fullWidth>
          <InputLabel sx={{ backgroundColor: "white", px: 1 }}>
            *Select a Devotional Topic
          </InputLabel>
          <Select
            value={selectedTopic?.name ?? ""}
            onChange={(e) => handleSelectTopic(e.target.value)}
          >
            {topics.map((topic) => (
              <MenuItem key={topic.id} value={topic.name}>
                {topic.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <StyledDropZoneContainer>
          <DropZone
            currentFile={devotional?.imageUrl as string}
            uploading={isUploading}
            onDrop={onFileDrop}
            disabled={isPast}
          />
        </StyledDropZoneContainer>
        <StyledContentContainer>
          {contentTypes.map((type) => (
            <ContentForm
              key={type}
              contentType={type}
              content={devotional?.[type as CONTENT_TYPE] ?? null}
              onContentChange={
                (updatedContent) => updateDevotional({ [type]: updatedContent }) // Update the correct field dynamically
              }
              disabled={isPast}
              // onValidate={handleFormValidate}
              onValidate={(isValid) =>
                handleFormValidate(type as CONTENT_TYPE, isValid)
              } // Track validation per type
            />
          ))}
        </StyledContentContainer>
        <Footer
          canSubmit={isFormValid}
          saving={saving}
          alreadyPast={isPast}
          onSubmit={onSave}
        />
      </StyledModalInnerContainer>
    </Modal>
  );
};
