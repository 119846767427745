import { config } from "../../constants/config";
import { Response } from "../../types";
import { getToken } from "../auth";
import { toDateString } from "../date";
import { ImageUpload, Content } from "./types";

const uniqueIdGenerator = () => {
  return Math.random().toString(36).substr(2, 9);
};

export const updateContent = async (
  content: Partial<Content> & { id: string },
) => {
  const accessToken = getToken();

  const response = await fetch(`${config.API_URL}/content/${content.id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(content),
  });

  if (response.status !== 200) {
    const respJson: Response<void> = await response.json();
    throw new Error(respJson.message);
  }

  const { data }: Response<Content> = await response.json();

  return data;
};

export const createContent = async (content: Partial<Content>) => {
  const accessToken = getToken();

  const response = await fetch(`${config.API_URL}/content`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      "ngrok-skip-browser-warning": "skip-browser-warning",
    },
    body: JSON.stringify(content),
  });

  if (response.status !== 200) {
    const respJson: Response<void> = await response.json();
    throw new Error(respJson.message);
  }

  const { data }: Response<Content> = await response.json();

  return data;
};

export const uploadImage = async (image: File) => {
  const accessToken = getToken();

  const formData = new FormData();

  formData.append("file", image);
  formData.append("keyPath", uniqueIdGenerator());

  const response = await fetch(`${config.API_URL}/content/upload-image`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      // "Content-Type": "multipart/form-data",
    },
    body: formData,
  });

  if (response.status !== 200) {
    const respJson: Response<void> = await response.json();

    throw new Error(respJson.message);
  }

  const { data }: Response<ImageUpload> = await response.json();

  return data;
};

export const getContentsBetweenDate = async (
  startDate: Date,
  endDate: Date,
) => {
  const accessToken = getToken();

  const startDateString = toDateString(startDate);
  const endDateString = toDateString(endDate);

  const urlPath = `from/${startDateString}/to/${endDateString}`;

  const response = await fetch(`${config.API_URL}/content/${urlPath}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.status !== 200) {
    const respJson: Response<void> = await response.json();
    throw new Error(respJson.message);
  }

  const { data }: Response<Record<string, Content[]>> = await response.json();

  return data;
};
